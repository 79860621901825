@import "~@ui/styles/tools";

.container {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 420px;
    border-radius: 12px;
    background: #FFFFFF1A;
    height: 70px;
}

.container--margin {
    margin: 0 -30px;
}

.item-wrapper {
    position: relative;
    margin: 0;
    margin: 0 auto;
    width: 45px;
    height: 50px;
}

@include media-mobile {
    .container {
        width: 100%;
    }
}