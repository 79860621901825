@import "~@ui/styles/tools";

@mixin big-breakpoint {
    @media screen and (max-width: 1750px) {
        @content;
    }
}

@mixin medium-breakpoint {
    @media screen and (max-width: 1550px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1400px) {
        @content;
    }
}

@mixin extra-small-breakpoint {
    @media screen and (max-width: 875px) {
        @content;
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.heading {
    font-size: 48px;
    font-weight: 600;
    line-height: 53px;

    @include media-tablet {
        font-size: 38px;
        font-weight: 500;
        line-height: 38px;
    }

    @include media-mobile {
        font-size: 28px;
        font-weight: 500;
        line-height: 31px;
    }
}

.info {
    display: grid;
    gap: 44px;
    margin-top: 30px;
    grid-template-columns: repeat(3, 0fr);
    width: fit-content;

    @include small-breakpoint {
        margin-top: 10px;
        gap: 20px;
    }

    @include media-tablet {
        gap: 24px;
        margin-top: 20px;
    }

    @include media-tablet-portrait {
        grid-template-columns: 1fr 1fr;
    }
}

.infoContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: fit-content;

    @include small-breakpoint {
        gap: 8px;
    }

    @include media-tablet {
        gap: 16px;
    }
}

.action {
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    color: #A2A8AD;
    white-space: nowrap;

    @include small-breakpoint {
        font-size: 22px;
        line-height: 22px;
    }

    @include media-mobile {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
    }
}

.text {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    @include medium-breakpoint {
        font-size: 22px;
        line-height: 22px;
    }

    @include media-tablet {
        line-height: 28px;
    }

    @include media-mobile {
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
    }
}

.activeText {
    color: $color-primary;
}

.candy {
    position: absolute;
    width: 40%;
    aspect-ratio: 1 / 1;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 0;

    @include big-breakpoint {
        transform: rotate(37deg);
        right: -35px;
        width: 35%;
    }

    @include medium-breakpoint {
        transform: rotate(0);
        right: 0;
        bottom: -115px;
    }

    @include media-tablet {
        bottom: 0px;
        transform: rotate(-45deg);
        right: -40px;
        width: 30%;
    }

    @include media-tablet-portrait {
        right: -10px;
        width: 40%;
        transform: rotate(-35deg);
    }

    @include media-mobile {
        transform: rotate(-45deg);
        bottom: 25%;
        right: 0;
        width: 40%;
    }
}

.buttons {
    display: flex;
    gap: 20px;
    width: 40%;
    margin-top: 5px;
    z-index: 1;

    @include medium-breakpoint {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 70%;
    }

    @include media-tablet {
        margin-top: 40px;
        width: fit-content;
    }

    @include media-mobile {
        gap: 12px;
        width: 100%;
        grid-template-columns: 1fr;
    }
}

.buttonContainer {
    @include media-mobile {
        margin: 0;
        padding: 0;
        border: none;
    }
}
